.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999999999999 !important;
}

.modalContent {
  background: white;
  padding: 0px;
  border-radius: 5px;
  position: relative;
  max-height: 84vh;
  overflow: auto;
  margin-top: 50px;
  width: 50%;
  display: flex;
  flex-direction: column;
  z-index: 99999999999999999 !important;
  @media screen and (max-width: 580px) {
    width: 100%;
    max-height: 80vh;
    margin-top: 45px;
  }
  @media screen and (max-width: 450px) {
    max-height: 75vh;
    margin-top: 15px;
  }
  @media screen and (max-width: 350px) {
    max-height: 73vh;
    margin-top: 35px;
  }
}

.closeButton {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  z-index: 99999999999999999 !important;
}

.filterheader {
  width: 100%;
  height: 70px;
  padding-left: 15px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  font: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  position: sticky;
  top: 0;
  background: white;
  z-index: 99999999999999999 !important;
}

.modalBody {
  padding: 20px;
  flex-grow: 1;
  overflow-y: auto;
  z-index: 99999999999999999 !important;
}

.modalFooter{
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 5px;
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99999999999999999 !important;
}

.clearButton{
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  z-index: 99999999999999999 !important;
}

.clearButton:hover{
  color: #1c7881;
}

.submitButton{
  padding-left: 20px;
  padding-right: 20px;
  z-index: 99999999999999999 !important;
}

.modalFooter {
  padding-right: 20px;
  padding-left: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ccc;
  z-index: 99999999999999999 !important;
}
