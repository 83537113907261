.searchOuter {
  margin-top: 10px;
  z-index: 9;
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;
}

.searchLink_active {
  width: 100%;
  max-width: 300px;
  /*   margin-bottom: 10px; */
}

.searchLink {
  opacity: 0;
}

.topbarSearchWithLeftPadding {
  /*   padding-left: 4px; */
  height: 50px;
  border: none;
}
