@import '../../styles/customMediaQueries.css';


:root {
  /*
    These variables are available in global scope through ":root"
    element (<html> tag). Variables with the same names are going to
    overwrite each other if CSS Properties' (PostCSS plugin)
    configuration "preserve: true" is used - meaning that variables
    are left to CSS bundle. We are planning to enable it in the future
    since browsers support CSS Properties already.
 */
  --LocationAutocompleteInput_inputHeight: 50px;
  --LocationAutocompleteInput_sidePadding: 24px;
  --LocationAutocompleteInput_sidePaddingDesktop: 36px;
}

.container {
  display: flex;
}

@media screen and (max-width: 576px) {
  .container {
    flex-direction: row;
  }
}

.root {
  position: relative;
  display: flex;
}

.icon {
  display: flex;
  width: 24px;
  align-self: stretch;
  border-bottom: 2px solid var(--marketplaceColor);
  background-color: var(--matterColorLight);
  padding-left: 15px;
}

.iconSvg {
  margin: auto;
}

.iconGlassSearchBtn {
  stroke: var(--iconColor);
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}

.iconSvgGroup {
  stroke: #1c7881;
  stroke-width: 2.5px;

  @media (--viewportMedium) {
    stroke-width: 2px;
  }
}

.iconSpinner {
  margin: auto;
  width: 23px;
}

/* .iconGlass{
  padding-left: 20px;
} */

.iconGlassSearchBtn {
  color: #ffffff;
  background-color: #ffffff;
}

.input {
  flex-grow: 1;
  height: var(--LocationAutocompleteInput_inputHeight);
  padding-left: 10px;
  margin: 0;
  line-height: unset;
  border-bottom-width: 2px;

  /* Safari bugfix: without this Safari will print placeholder to a wrong place */
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &:hover,
  &:focus {
    border-bottom-color: var(--marketplaceColor);
    outline: none;
  }
}

/*
Predictions container can be overriden with new container styles for
size and position, etc.
*/
.predictionsRoot {
  position: absolute;
  width: 100%;
  padding-bottom: var(--locationAutocompleteBottomPadding);
  top: var(--LocationAutocompleteInput_inputHeight);
  left: 0;
  background-color: var(--marketplaceColor);
  border-bottom-left-radius: var(--borderRadius);
  border-bottom-right-radius: var(--borderRadius);
  box-shadow: var(--boxShadowPopup);
  z-index: calc(var(--zIndexPopup) + 1);
  padding-bottom: 50px;
}

/*
The Google Maps API TOS requires us to show a Powered by Google logo
next to the autocomplete service predictions. It is rendered to the
bottom of the container.
*/
.poweredByGoogle {
  position: absolute;
  bottom: 15px;
  width: 100%;
  height: 18px;
  background-image: url(./images/powered_by_google_on_non_white_hdpi.png);
  background-size: auto 18px;
  background-position: center;

  @media (--viewportMedium) {
    background-position: center left var(--LocationAutocompleteInput_sidePaddingDesktop);
  }
}

/* List of predictions, with a responsive padding size */
.predictions {
  composes: marketplaceBodyFontStyles from global;

  margin: 0;
  padding: 14px 0;

  & li {
    color: var(--matterColorLight);
    transition: var(--transitionStyleButton);
    margin: 0; /* Remove the double white space */
    line-height: 24px;

    /* Assign enough vertical padding to make the element at least 44px high */
    padding: 9px var(--LocationAutocompleteInput_sidePadding);

    @media (--viewportMedium) {
      color: var(--matterColorLightTransparent);
      padding: 10px var(--LocationAutocompleteInput_sidePaddingDesktop);
      margin: 0;
    }

    &:hover,
    &.highlighted {
      cursor: pointer;
      border-left: 6px solid var(--matterColorLight);
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      color: var(--matterColorLight);
    }
  }
}

.currentLocation {
  composes: h4 from global;
}

.currentLocationIcon {
  margin-right: 10px;
}

.locationSearchBtn {
  composes: marketplacePaperBackground from global;
  composes: marketPlaceLandingPageSearchButton from global;
  border: none;
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
  margin-left: 20px;
  z-index: 0;
  position: relative;
  animation: startAnimation 3s;
  width: 180px;
  height: 61px;
  box-shadow: 0px 4px 15px rgba(235, 114, 66, 0.4);
  cursor: pointer;
  @media (--viewportMedium) {
    position: relative;
    width: 180px;
  }
}
.locationSearchBtn:hover {
  background-color: #353535;
}

@media screen and (max-width: 576px) {
  .locationSearchBtn {
    margin-left: 0;
    width: 20vw;
    height: 61px;
    border-bottom-right-radius: 11px;
    border-top-right-radius: 11px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }
}

/* Small Devices, Tablets */
@media screen and (min-width: 577px) and (max-width: 992px) {
  .locationSearchBtn {
    margin-left: 0;
    width: auto;
    height: 61px;
    padding: 10px;
    border-bottom-right-radius: 11px;
    border-top-right-radius: 11px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }
}
