@import '../../../styles/customMediaQueries.css';


.root {
  display: flex;
  flex-direction: column;
  background-color: var(--colorGrey50);
  width: 100%;
  z-index: 9999999999999;
  padding: 0.5em 0.5em;
  border-radius: 11px;
  border: 1px solid grey;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  @media screen and (max-width: 560px) {
    margin-top: 10px;
  }
}

.filters {
  & > *:not(:last-child) {
    margin-right: 8px;
  }
}

.filters {
  @media screen and (min-width: 560px) {
    & > *:nth-child(n + 3) {
      margin-top: 5px;
    }
  }
  @media screen and (max-width: 560px) {
    & > *:not(:first-child) {
      margin-top: 5px;
    }
  }
}

.filterItem {
  border: 1px solid black;
  padding: 5px;
  width: fit-content;
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;

  &:active {
    transform: scale(0.97);
  }
}

.filterItemGuests {
  border: 1px solid black;
  padding: 5px;
  height: 90%;
  width: fit-content;
  border-radius: 3px;
  cursor: pointer;
  white-space: nowrap;
  max-width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  @media screen and (max-width: 560px) {
    display: none;
  }
}

.mainFiltersWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 10px;
  z-index: 99999 !important;
  width: fit-content;
  @media screen and (max-width: 560px) {
    min-width: 140px;
  }
}

.searchIcon {
  transform: scaleX(-1);
  background-color: #1c7881;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
}
.searchIcon:hover {
  cursor: pointer;
}

.searchIconMain {
  color: white;
  stroke: white;
}

.searchFilterItem {
  border: none;
  min-width: 160px;
  border-radius: 3px;
  @media screen and (max-width: 560px) {
    display: none;
  }
}

.searchFilterIcon {
  border: none;
  width: fit-content;
  border-radius: 3px;
  @media screen and (max-width: 560px) {
    display: none;
  }
}
.searchFilterIconMobile{
  border: none;
  width: fit-content;
  border-radius: 3px;
/*   background-color: red; */
  height: 50px;
  width: 50px;
}

.paramIcon {
  width: 20px;
  height: 15px;
}

.divider {
  border: 1px solid black;
  width: 1px;
  height: 40px;
}

.filter {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 8px;
}

.filterContainer {
  display: flex;
  align-items: space-between;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  width: 100%;
}

.dateInputs {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 5px;
  @media screen and (max-width: 560px) {
    justify-content: space-between;
    width: 100%;
  }
}

.dateInput {
  border: 1px solid black;
  width: 100px !important;
  height: 50px;
  border-radius: 3px;
  color: black;
  @media screen and (max-width: 560px) {
    width: 160px !important;
  }
  @media screen and (max-width: 340px) {
    width: 120px !important;
  }
}

.dateInput::-webkit-datetime-edit-fields-wrapper {
  display: none;
}

.datesPlaceholder {
  font-size: 1rem;
  color: #a1a1a1;
}

.filterPlain {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 5px;
}

.filterItemPlain {
  border: 1px solid black;
  padding: 5px;
  width: fit-content;
  border-radius: 3px;
  cursor: pointer;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 48px;
}

.filterButtonPlain {
  display: flex;
  align-items:flex-end;
  justify-content: center;
  flex-direction: column;
  height: 70px;
  gap: 0px;
}

.searchFilterItemPlain {
  display: flex;
  align-items:flex-end;
  justify-content: center;
  flex-direction: row;
  gap: 6px;
  height: 70px;
}

.searchIconPlain {
  transform: scaleX(-1);
  /*   background-color: #1c7881; */
  padding: 10px 10px 5px 10px;
  cursor: pointer;
  height: 48px;
}
.searchFilterPlainWrapper {
  background-color: #1c7881;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  color: white;
  padding-right: 10px;
  border-radius: 3px;
  cursor: pointer;
}

.results {
  color: #1c7881;
  font-size: 15px;
  white-space: nowrap;
}

.dateInput:focus + .dateLabel,
.dateInput::placeholder {
  display: none;
}

/* .searchFilterItem{
  width: 280px;
} */

.guestsMobile {
  display: none;
  @media screen and (max-width: 560px) {
    display: block;
  }
}

.mapIcon {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  /* background map image */
  background-image: url(./map_icon216x105.png);
  background-size: cover;

  border-radius: 3px;
  width: 62px;
  height: 35px;
  padding: 9px 16px;
  margin: 0;

  @media screen and (min-width: 560px) {
    display: none;
  }
}

.searchFilterItemMobile {
  border: none;
  width: fit-content !important;
  border-radius: 3px;
  /*   @media screen  and (max-width: 560px) {
    display: none;
  } */
}

.label {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.closeIcon {
  font-size: large;
  font-weight: bold;
  margin-top: -5px;
  cursor: pointer;
}

.datesDisplay{
  display: flex;
  width: 100%;
  flex-direction: column;

}

.searchIconMobile {
  transform: scaleX(-1);
  background-color: #1c7881;
  padding: 5px 10px 5px 10px;
  cursor: pointer;
  height: 50px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.searchIconMobile:hover {
  cursor: pointer;
}

.emptyclass{
  position: absolute;
  height: 300px;
  width: 80vw;
  top: 500px;
}

.emptyclass2{
  position: fixed;
  height: 100vh;
  width: 42vw;
  top: 0;
  right: 0;
}
