@import '../../../styles/customMediaQueries.css';


.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
  position: relative;
  top: 4em;
  width: 300px;
}

.filtersWrapper {
  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    padding: 1em 2em;
    /* grid-gap: 72px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: auto; */
  }
  /* 
  @media (--viewportLarge) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(3, 1fr);
  } */
}

.footer {
  display: flex;
  margin: 2em 0 0 0;
  padding: 1em 1em 1em 1em;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
}
.footer2 {
  position: fixed;
  margin: 2em 0 0 0;
  padding: 1em 1em 1em 1em;
  background-color: #ffffff;
  border-color: black;
  border-radius: 1em;
  border-style: solid;
  border-width: thin;
  bottom: 0;
  width: 58vw;
  @media screen and (max-width: 1300px) {
    width: 55vw;
  }
  @media screen and (max-width: 1000px) {
    width: 43vw;
  }
}

.filterButton {
  /* Position and dimensions */
  display: inline;
  padding: 0;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  /* margin-top + n * line-height + margin-bottom => x * 6px */
  margin-top: 10px;
  margin-bottom: 8px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 10px;
    margin-bottom: 14px;
  }
}
.resetAllButton {
  composes: filterButton;
  color: var(--colorGrey300);

  /* Layout */
  margin: 0 auto 0 0;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.cancelButton {
  position: relative;
  left: 70%;
  composes: filterButton;
  color: var(--colorGrey300);

  /* Layout */
  margin: 0 24px 0 24px;

  /* Override button styles */
  outline: none;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
  @media screen and (max-width: 1500px) {
    left: 60%;
  }
  @media screen and (max-width: 1300px) {
    left: 55%;
  }
  @media screen and (max-width: 1100px) {
    left: 40%;
  }
  @media screen and (max-width: 1000px) {
    left: 5%;
  }
  @media screen and (max-width: 800px) {
    margin: 0 0 0 9px;
    padding: 0;
    left: 1%;
  }
}

.applyButton {
  position: relative;
  left: 70%;
  composes: filterButton;
  /* Override button styles */
  outline: none;
  composes: marketplaceButtonStylesPrimary from global;

  /* Layout */
  margin: 0 0 0 19px;
  padding: 0.5em 1em;
  border-radius: 11px;
  &:focus,
  &:hover {
    transform: translateY(2px);
  }
  @media (min-width: 768px) and (max-width: 979px) {
  }
  margin: 0 0 0 -6px;
  @media screen and (max-width: 1500px) {
    left: 60%;
  }
  @media screen and (max-width: 1300px) {
    left: 55%;
  }
  @media screen and (max-width: 1100px) {
    left: 40%;
  }
  @media screen and (max-width: 1000px) {
    left: 5%;
  }
}
